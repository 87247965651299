<table [dataSource]="store"
       cdk-table
       class="table table-borderless table-bigrow table-bigrow-hover"
       matSort
       matSortActive="name"
       matSortDirection="asc"
       matSortDisableClear
       multiTemplateDataRows>
    <tr *cdkHeaderRowDef="displayedColumns" cdk-header-row></tr>
    <tr *cdkRowDef="let row; columns: displayedColumns;" cdk-row></tr>

    <tr *matNoDataRow class="mat-row">
        <td class="mat-cell text-center" colspan="7">
            Pas d’élément correspondant à votre recherche
        </td>
    </tr>

    <ng-container cdkColumnDef="id">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="id">#</th>
        <td *cdkCellDef="let element" cdk-cell class="table__id--width table__id--bold">
            #{{element.id}}
            <mcv-button-trz-document *ngIf="element.trz_documentId"
                                     [size]="'tiny'"
                                     [trz_documentId]="element.trz_documentId"></mcv-button-trz-document>
        </td>
    </ng-container>
    <ng-container cdkColumnDef="owner">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="cse">Propriétaire</th>
        <td *cdkCellDef="let element" cdk-cell class="table__id--width table__id--bold">
            <div *ngIf="element.owner_type == 'cse'"><a [routerLink]="['/crm/cse/view/',element.cse_id,'kyc']"
                                                        nbButton
                                                        nbTooltip="View"
                                                        status="primary"><i
                class="far fa-eye fa-fw"></i></a></div>
            <div *ngIf="element.owner_type == 'cse_user'"><a
                [routerLink]="['/crm/cse-users/view/',element.owner_id,'kyc']"
                nbButton
                nbTooltip="View"
                status="primary"><i
                class="far fa-eye fa-fw"></i></a></div>
            <div *ngIf="element.owner_type == 'cse_employe'"><a
                [routerLink]="['/crm/cse-employes/view/',element.owner_id,'kyc']"
                nbButton
                nbTooltip="View"
                status="primary"><i
                class="far fa-eye fa-fw"></i></a></div>
        </td>
    </ng-container>
    <ng-container cdkColumnDef="filename">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="name">Nom du document</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width {{store.data[store.data.indexOf(element)] | wsClass:'filename'}}"
            style="word-break: keep-all">
            {{element.filename}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="name">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="type">Type de document</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width {{store.data[store.data.indexOf(element)] | wsClass:'name'}}"
            style="word-break: keep-all">
            {{element.name}}
        </td>
    </ng-container>

    <ng-container cdkColumnDef="date_update">
        <th *cdkHeaderCellDef cdk-header-cell>Date de dernière mise à jour</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__state--width {{store.data[store.data.indexOf(element)] | wsClass:'date_update'}}"
            style="word-break: keep-all">
            {{element.date_update || element.date_create | date:'longDate'}}
        </td>
    </ng-container>

    <ng-container cdkColumnDef="state">
        <th *cdkHeaderCellDef cdk-header-cell>Statut</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__state--width {{store.data[store.data.indexOf(element)] | wsClass:'state'}}"
            style="word-break: keep-all">
            <span [innerHTML]="element | cseDocumentStatut"></span>
        </td>
    </ng-container>

    <ng-container cdkColumnDef="treezor_comment">
        <th *cdkHeaderCellDef cdk-header-cell>Treezor</th>
        <td *cdkCellDef="let element" cdk-cell class="table__state--width {{store.data[store.data.indexOf(element)]}}"
            style="word-break: keep-all">
            <span [innerHTML]="element | cseDocumentTreezorComment"></span>
        </td>
    </ng-container>

    <ng-container cdkColumnDef="actions">
        <th *cdkHeaderCellDef cdk-header-cell>
            <div>Action</div>
        </th>
        <td *cdkCellDef="let element" cdk-cell class="cell--action__width">
            <mcv-cse-document-synthesis (refresh)="refreshData()" [document]="element"></mcv-cse-document-synthesis>
        </td>
    </ng-container>
</table>
<nb-spinner *ngIf="store.loading$ | async"></nb-spinner>

<br/>
<ng-container *ngIf="paging$ | async as pagingInfo">
    <mat-paginator (page)="changePage($event)"
                   *ngIf="pagingInfo.count > limit"
                   [color]="'primary'"
                   [length]="pagingInfo.count"
                   [pageIndex]="pagingInfo.current_page - 1"
                   [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="limit">
    </mat-paginator>
</ng-container>
