import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {TrzToolsService} from '@crm/app/trz-tools/services/trz-tools.service';
import {McvnotifierService} from '@mcv/ui';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-trz-document',
    templateUrl: './trz-document.component.html',
    styleUrls: ['./trz-document.component.scss']
})
export class TrzDocumentComponent implements OnInit {
    @Input() documentId: string;
    @Input() userId: string;
    searchForm: FormGroup = this.fb.group({
        documentId: [],
        userId: []
    });
    result: any;
    searching = false;

    constructor(private fb: FormBuilder,
                private activatedRoute: ActivatedRoute,
                private notifierService: McvnotifierService,
                private trzToolsService: TrzToolsService) {
    }

    ngOnInit(): void {
        if (!this.documentId && !this.userId) {
            this.readRouteParams();
        } else {
            this.readFromInput();
        }
    }

    async search() {
        try {
            this.searching = true;
            this.result = await this.trzToolsService
                .searchDocument(this.searchForm.value)
                .pipe(map(r => r.data))
                .toPromise();
        } catch (e) {
            this.result = null;
            this.notifierService.error(e);
        } finally {
            this.searching = false;
        }
    }

    private readFromInput() {
        this.searchForm.patchValue({
            userId: this.userId,
            documentId: this.documentId
        });
        this.search();
    }

    private readRouteParams() {
        if (this.activatedRoute.snapshot.queryParamMap.has('documentId')) {
            this.searchForm.patchValue({documentId: this.activatedRoute.snapshot.queryParamMap.get('documentId')});
            this.search();
        }
    }

}
