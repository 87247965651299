import {Component, Input, OnInit} from '@angular/core';
import {NbComponentSize} from '@nebular/theme/components/component-size';
import {WindowManagerService} from '@crm/app/window-manager/services/window-manager.service';
import {TrzBalanceComponent} from '@crm/app/trz-tools/ui/trz-balance/trz-balance.component';

@Component({
    selector: 'mcv-button-trz-balance',
    templateUrl: './button-trz-balance.component.html',
    styleUrls: ['./button-trz-balance.component.scss']
})
export class ButtonTrzBalanceComponent implements OnInit {
    @Input() trz_walletId: number;
    @Input() onlyIcon = true;
    @Input() displayText = true;
    @Input() size: NbComponentSize = 'tiny';

    constructor(private windowService: WindowManagerService) {
    }

    ngOnInit(): void {
    }

    openBalance() {
        this.windowService.open(TrzBalanceComponent, {
            context: {walletId: this.trz_walletId},
            title: `Balance #${this.trz_walletId}`
        });
    }
}
