import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdminTransactionService} from '@crm/app/shared/services/admin-transaction.service';
import {AdminWalletService} from '@crm/app/shared/services/admin-wallet.service';
import {ModalConfirmationComponent} from '@crm/app/shared/ui/modal-confirmation/modal-confirmation.component';
import {TrzToolsService} from '@crm/app/trz-tools/services/trz-tools.service';
import {McvnotifierService} from '@mcv/ui';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {CseEmployeService, CseService} from '@mcv/coreservices';
import {Cse, CseEmploye} from '@mcv/core';
import {Router} from '@angular/router';
import {
    CseEmployeTopupPayoutComponent
} from '@crm/app/cse-employe-topup/ui/cse-employe-topup-payout/cse-employe-topup-payout.component';
import {WindowManagerService} from '@crm/app/window-manager/services/window-manager.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-trz-view-tools',
    templateUrl: './trz-view-tools.component.html',
    styleUrls: ['./trz-view-tools.component.scss']
})
export class TrzViewToolsComponent implements OnInit {
    @Input() cse: Cse;
    @Input() cseEmploye: CseEmploye;
    @Input() trz_walletId: number;
    blocking: boolean;
    saving: boolean;
    syncing: boolean;
    syncingTransac: any;
    syncingBalance: any;
    deleting = false;
    confirmDeleteForm: FormGroup = this.fb.group({
        name: [null, [Validators.required]]
    });

    constructor(private adminWalletService: AdminWalletService,
                private trzToolsService: TrzToolsService,
                private notifierService: McvnotifierService,
                private fb: FormBuilder,
                private dialogService: NbDialogService,
                private router: Router,
                private cseService: CseService,
                private cseEmployeService: CseEmployeService,
                private adminTransactionService: AdminTransactionService,
                private windowManager: WindowManagerService) {
    }

    ngOnInit(): void {
        const validators = [];
        if (this.cse) {
            validators.push(Validators.pattern(this.cse.legalName), Validators.required);
        }
        this.confirmDeleteForm.get('name')
            .setValidators(validators);
    }

    async syncWallet() {
        try {
            this.syncing = true;
            await this.adminWalletService.sync(this.trz_walletId)
                .toPromise();
            this.notifierService.success('Synchronisation terminée');
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.syncing = false;
        }
    }

    async syncTransac() {
        try {
            this.syncingTransac = true;
            await this.adminTransactionService
                .sync(this.trz_walletId)
                .toPromise();
            this.notifierService.success('Synchronisation terminée');
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.syncingTransac = false;
        }
    }

    async delete(dialogRef: NbDialogRef<any>) {
        try {
            this.deleting = true;
            await this.cseService.delete(this.cse.public_id)
                .toPromise();
            this.notifierService.success('Le CSE a été supprimé');
            dialogRef.close();
            this.router.navigate(['/crm/cse']);
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.deleting = false;
        }
    }

    askDelete(dialogRef: TemplateRef<any>) {
        this.dialogService.open(dialogRef);
    }

    async syncBalance() {
        try {
            this.syncingBalance = true;
            await this.trzToolsService
                .syncBalance(this.trz_walletId)
                .toPromise();
            this.notifierService.success('Synchronisation en cours');
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.syncingBalance = false;
        }
    }

    openRefundModal(event) {
        this.windowManager.open(CseEmployeTopupPayoutComponent, {
            windowClass: 'large',
            title: 'Payout',
            context: {cseEmploye: this.cseEmploye}
        });
    }

    async askRemove(): Promise<void> {
        this.dialogService.open(ModalConfirmationComponent, {
            context: {
                title: 'Confirmation de sortie d\'un employé',
                confirmButtonLabel: 'Oui, supprimer',
                cancelButtonLabel: 'Non, c\'est une erreur',
                confirmText: 'En cliquant sur supprimer, vous allez déclarer l\'employé comme ne faisant plus partie du CSE, un mail sera envoyé à l\'employé lui notifiant cette sortie. Êtes-vous sûr de vouloir continuer ?'
            }
        })
            .onClose
            .subscribe(r => {
                if (r) {
                    this.remove();
                }
            });
    }

    async toggleBlock() {
        try {
            this.blocking = true;
            const fn = this.cseEmploye.blocked ? this.cseEmployeService.unlockAccount(this.cseEmploye.public_id) : this.cseEmployeService.lockAccount(this.cseEmploye.public_id);
            await fn.toPromise();
            this.notifierService.success('Opération réussie');
            this.cseEmploye.blocked = !this.cseEmploye.blocked;
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.blocking = false;
        }
    }

    private async remove() {
        try {
            this.saving = true;
            const employeUpdated = await this.cseEmployeService
                .remove(this.cseEmploye.public_id)
                .pipe(
                    map(r => r.data)
                )
                .toPromise();
            this.cseEmploye = {...this.cseEmploye, ...employeUpdated};
            this.notifierService.success('L\'employé a été enlevé du CSE');
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.saving = false;
        }
    }
}
