import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {McvnotifierService} from '@mcv/ui';
import {TrzToolsService} from '@crm/app/trz-tools/services/trz-tools.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-trz-card',
    templateUrl: './trz-card.component.html',
    styleUrls: ['./trz-card.component.scss']
})
export class TrzCardComponent implements OnInit {
    @Input() cardId: string;
    @Input() userId: string;
    searchForm: FormGroup = this.fb.group({
        cardId: [],
        userId: []
    });
    result: any;
    searching = false;

    constructor(private fb: FormBuilder,
                private activatedRoute: ActivatedRoute,
                private notifierService: McvnotifierService,
                private trzToolsService: TrzToolsService) {
    }

    ngOnInit(): void {
        if (!this.cardId && !this.userId) {
            this.readRouteParams();
        } else {
            this.readFromInput();
        }
    }

    async search() {
        try {
            this.searching = true;
            this.result = await this.trzToolsService
                .searchCard(this.searchForm.value)
                .pipe(map(r => r.data))
                .toPromise();
        } catch (e) {
            this.result = null;
            this.notifierService.error(e);
        } finally {
            this.searching = false;
        }
    }

    private readFromInput() {
        this.searchForm.patchValue({
            userId: this.userId,
            cardId: this.cardId
        });
        this.search();
    }

    private readRouteParams() {
        if (this.activatedRoute.snapshot.queryParamMap.has('cardId')) {
            this.searchForm.patchValue({cardId: this.activatedRoute.snapshot.queryParamMap.get('cardId')});
            this.search();
        }
    }
}
