<div *ngIf="bic">{{bic}}</div>
<div *ngIf="iban">{{iban}}</div>
<div *ngIf="walletId" class="d-flex align-items-center">
    <mcv-button-trz-wallet [displayText]="true" [onlyIcon]="true" [trz_walletId]="walletId"
                           class="mr-2"></mcv-button-trz-wallet>
    <mcv-button-trz-balance [displayText]="false" [onlyIcon]="true" [trz_walletId]="walletId"
                            class="mr-2"></mcv-button-trz-balance>
    <mcv-button-trz-transaction [displayText]="false" [onlyIcon]="false"
                                [trz_walletId]="walletId"></mcv-button-trz-transaction>
</div>
