<nb-card>
    <nb-card-header>Outils</nb-card-header>
    <nb-card-body>
        <button (click)="syncWallet()"
                [nbSpinner]="syncing"
                fullWidth
                nbButton
                nbTooltip="Synchroniser le wallet avec Trz"
                size="medium"
                status="primary"
                type="button">
            <i class="fad fa-sync-alt"></i>&nbsp;Synchroniser le Wallet
        </button>
        <button (click)="syncBalance()"
                [nbSpinner]="syncingBalance"
                fullWidth
                nbButton
                nbTooltip="Synchroniser la balance avec Trz"
                size="medium"
                status="primary"
                type="button">
            <i class="fad fa-sync-alt"></i>&nbsp;Synchroniser la balance
        </button>
        <button (click)="syncTransac()"
                [nbSpinner]="syncingTransac"
                fullWidth
                nbButton
                nbTooltip="Synchroniser les transactions avec Trz"
                size="medium"
                status="primary"
                type="button">
            <i class="fad fa-sync-alt"></i>&nbsp;Synchroniser les transactions
        </button>
        <ng-container *ngIf="cseEmploye">
            <button (click)="openRefundModal($event)"
                    fullWidth
                    nbButton
                    size="medium"
                    status="danger"
                    type="button">
                Virement bancaire
            </button>
            <button (click)="askRemove()"
                    *ngIf="!cseEmploye.outsider"
                    [disabled]="saving"
                    [nbSpinner]="saving"
                    fullWidth
                    nbButton
                    status="danger"
                    type="button">
                Sortir l'employé du CSE
            </button>
            <button (click)="toggleBlock()"
                    [disabled]="blocking"
                    [nbSpinner]="blocking"
                    fullWidth
                    nbButton
                    status="danger"
                    type="button">
                {{!!cseEmploye.blocked ? 'Débloquer' : 'Bloquer'}} le compte
            </button>
        </ng-container>
    </nb-card-body>
</nb-card>

<ng-template #confirmDialogRef let-data="data" let-ref="dialogRef">
    <form (ngSubmit)="delete(ref)" [formGroup]="confirmDeleteForm">
        <nb-card>
            <nb-card-header>Suppression CSE</nb-card-header>
            <nb-card-body>
                <nb-alert accent="danger">
                    <h1>La suppression d'un CSE est irréversible.</h1>
                    Si vous voulez sécuriser l'opération et que vous n'êtes pas sûr de ce que vous faites, il est
                    préférable de mettre le CSE en statut "closed".
                </nb-alert>
                <label>
                    Confirmez la suppression en saisissant le nom du CSE çi-dessous :
                </label>
                <input formControlName="name" fullWidth nbInput/>
            </nb-card-body>
            <nb-card-footer>
                <button (click)="ref.close()"
                        nbButton
                        status="primary"
                        type="button">
                    Annuler
                </button>
                <button [disabled]="deleting || !confirmDeleteForm.valid"
                        [nbSpinner]="deleting"
                        class="ml-2"
                        nbButton
                        status="danger"
                        type="submit">
                    Confirmer la suppression
                </button>
            </nb-card-footer>
        </nb-card>
    </form>
</ng-template>
