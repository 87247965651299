import {Component, Input, OnChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'mcv-transaction-filter',
    templateUrl: './transaction-filter.component.html',
    styleUrls: ['./transaction-filter.component.scss']
})
export class TransactionFilterComponent implements OnChanges {
    @Input() searchFilter: FormGroup;
    @Input() walletId: number;
    @Input() displayWalletFilter = false;

    constructor() {
    }

    ngOnChanges(): void {

    }
}
