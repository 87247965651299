import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'boolean'
})
export class BooleanPipe implements PipeTransform {

    transform(value: boolean, ...args: unknown[]): string {
        if (value) {
            return '<span class="tag tag-bg-success"><i class="fas fa-check fa-fw"></i></span>';
        }
        return '<span class="tag tag-bg-danger"><i class="fas fa-times fa-fw"></i></span>';
    }
}
