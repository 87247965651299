import {Pipe, PipeTransform} from '@angular/core';
import {Wallet} from '@crm/interfaces/Wallet';

@Pipe({
    name: 'walletStateClass'
})
export class WalletStateClassPipe implements PipeTransform {

    transform(value: Wallet, ...args: unknown[]): string {
        switch (value.walletStatus) {
        case 'VALIDATED':
            return 'success';
        case 'CANCELLED':
            return 'danger';
        case 'PENDING':
            return 'warning';
        default :
            return 'primary';
        }
    }
}
