import { Component, Input, OnInit } from '@angular/core';
import { TrzToolsService } from '@crm/app/trz-tools/services/trz-tools.service';
import { CseDocumentService, CseEmployeKycService } from '@mcv/coreservices';
import { McvnotifierService } from '@mcv/ui';
import { NbComponentSize } from '@nebular/theme/components/component-size';

@Component({
    selector: 'mcv-button-kycreview',
    templateUrl: './button-kycreview.component.html',
    styleUrls: ['./button-kycreview.component.scss']
})
export class ButtonKycreviewComponent implements OnInit {
    @Input() trzUserId;
    @Input() size: NbComponentSize = 'small';
    @Input() isCseEmploye: boolean;
    reviewing = false;

    constructor(private trzToolsService: TrzToolsService,
                private cseDocumentService: CseDocumentService,
                private cseEmployeKycService: CseEmployeKycService,
                private notifierService: McvnotifierService) {
    }

    ngOnInit(): void {
    }

    async sendKycReview() {
        try {
            this.reviewing = true;
            if (this.isCseEmploye === true) {
                await this.cseEmployeKycService
                    .sendKycReview(this.trzUserId)
                    .toPromise();
            } else {
                await this.cseDocumentService
                    .sendAllKycReview(this.trzUserId)
                    .toPromise();
            }
            this.notifierService.success('Revue KYC/KYB envoyée');
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.reviewing = false;
        }
    }
}
