import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CseEmploye, CseEmployeTopup, VirementBeneficiary} from '@mcv/core';
import {FormlyFieldConfig, FormlyFormOptions} from '@ngx-formly/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {VirementBeneficiaryService} from '@mcv/coreservices';
import {McvnotifierService} from '@mcv/ui';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-cse-emploue-topup-payout-beneficiary-detail',
    templateUrl: './cse-emploue-topup-payout-beneficiary-detail.component.html',
    styleUrls: ['./cse-emploue-topup-payout-beneficiary-detail.component.scss']
})
export class CseEmploueTopupPayoutBeneficiaryDetailComponent implements OnInit {

    @Output() beneficiaryAdded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() virementBeneficiary?: VirementBeneficiary;
    @Input() cseEmploye: CseEmploye;
    @Input() topup?: CseEmployeTopup;
    isNewBeneficiary = false;
    pendingValidation = false;
    canDoTransfer = false;
    showTransferView = false;
    options: FormlyFormOptions = {
        formState: {
            disabled: false
        }
    };
    beneficiaryForm: FormGroup = this.fb.group({
        name: [null, [Validators.required]],
        iban: [null, [Validators.required]],
        bic: [null, [Validators.required]],
        public_id: [null],
        firstname: [null, [Validators.required]],
        lastname: [null, [Validators.required]],
        address1: [null, [Validators.required]],
        address2: [null],
        postcode: [null, [Validators.required]],
        city: [null, [Validators.required]],
        cse_employe_public_id: [null, [Validators.required]],
        is_active: [false]
    });
    loading = true;
    saving = false;
    beneficiaryFields: FormlyFieldConfig[] = [
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'Veuillez saisir les informations du bénéficiaire'},
            fieldGroup: [
                {
                    key: 'name',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Label',
                        placeholder: '',
                        required: true
                    },
                    expressionProperties: {
                        // apply expressionProperty for disabled based on formState
                        'templateOptions.disabled': 'formState.disabled'
                    }
                },
                {
                    key: 'iban',
                    className: 'col-6',
                    type: 'iban',
                    templateOptions: {
                        label: 'IBAN',
                        placeholder: '',
                        required: true
                    },
                    expressionProperties: {
                        // apply expressionProperty for disabled based on formState
                        'templateOptions.disabled': 'formState.disabled'
                    }
                },
                {
                    key: 'bic',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'BIC',
                        placeholder: '',
                        required: true
                    },
                    expressionProperties: {
                        // apply expressionProperty for disabled based on formState
                        'templateOptions.disabled': 'formState.disabled'
                    }
                },
                {
                    key: 'firstname',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Prénom',
                        placeholder: '',
                        required: true
                    },
                    expressionProperties: {
                        // apply expressionProperty for disabled based on formState
                        'templateOptions.disabled': 'formState.disabled'
                    }
                },
                {
                    key: 'lastname',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Nom',
                        placeholder: '',
                        required: true
                    },
                    expressionProperties: {
                        // apply expressionProperty for disabled based on formState
                        'templateOptions.disabled': 'formState.disabled'
                    }
                },
                {
                    key: 'address1',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Adresse 1',
                        placeholder: '',
                        required: true
                    },
                    expressionProperties: {
                        // apply expressionProperty for disabled based on formState
                        'templateOptions.disabled': 'formState.disabled'
                    }
                },
                {
                    key: 'address2',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Adresse 2',
                        placeholder: '',
                        required: false
                    },
                    expressionProperties: {
                        // apply expressionProperty for disabled based on formState
                        'templateOptions.disabled': 'formState.disabled'
                    }
                },
                {
                    key: 'postcode',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Code postale',
                        placeholder: '',
                        required: true
                    },
                    expressionProperties: {
                        // apply expressionProperty for disabled based on formState
                        'templateOptions.disabled': 'formState.disabled'
                    }
                },
                {
                    key: 'city',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Ville',
                        placeholder: '',
                        required: true
                    },
                    expressionProperties: {
                        // apply expressionProperty for disabled based on formState
                        'templateOptions.disabled': 'formState.disabled'
                    }
                },
                {
                    key: 'is_active',
                    className: 'col-6',
                    type: 'checkbox',
                    templateOptions: {
                        label: 'Activé ?',
                        placeholder: 'Le bénéficiaire est actif ?'
                    },
                    expressionProperties: {
                        // apply expressionProperty for disabled based on formState
                        'templateOptions.disabled': 'formState.disabled'
                    }
                }
            ]
        }];

    constructor(private fb: FormBuilder,
                private virementBeneficiaryService: VirementBeneficiaryService,
                private notifierService: McvnotifierService) {
    }

    ngOnInit() {
        if (this.virementBeneficiary !== null) {
            this.updateStat();
        } else {
            this.isNewBeneficiary = true;
            this.virementBeneficiary = {
                name: this.cseEmploye.fullname,
                iban: null,
                bic: null,
                firstname: this.cseEmploye.firstname,
                lastname: this.cseEmploye.lastname,
                address1: this.cseEmploye.address1,
                address2: this.cseEmploye.address2,
                postcode: this.cseEmploye.postcode,
                city: this.cseEmploye.city,
                is_active: false,
                state: null,
                public_id: null,
                cse_employe_public_id: this.cseEmploye.cse_employe_card_public_id
            } as VirementBeneficiary;
            this.beneficiaryForm.patchValue(this.virementBeneficiary);
        }
    }

    async saveBeneficiary(): Promise<void> {
        try {
            this.saving = true;
            this.beneficiaryForm.patchValue({
                public_id: this.virementBeneficiary?.public_id,
                cse_employe_public_id: this.cseEmploye.public_id
            });
            this.virementBeneficiary = await this.virementBeneficiaryService.createOrUpdate(this.beneficiaryForm.value).pipe(map(r => r.data)).toPromise();
            if (this.isNewBeneficiary) {
                this.beneficiaryAdded.emit(true);
                this.notifierService.success('le bénéficiaire a été ajouté');
            } else {
                this.updateStat();
                this.notifierService.success('le bénéficiaire a été mis à jour');
            }
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.saving = false;
        }
    }

    updateStat() {
        this.pendingValidation = this.virementBeneficiary?.state === 'pending';
        this.options.formState.disabled = this.pendingValidation;
        this.canDoTransfer = this.virementBeneficiary?.state === 'activated';
        this.virementBeneficiary.is_active = this.virementBeneficiary?.state === 'activated';
        this.beneficiaryForm.patchValue({
            is_active: this.virementBeneficiary.is_active
        });
        if (this.virementBeneficiary?.public_id == null) {
            this.isNewBeneficiary = true;
        }
        this.beneficiaryForm.patchValue(this.virementBeneficiary);
        this.beneficiaryForm.patchValue({
            public_id: this.virementBeneficiary?.public_id,
            cse_employe_public_id: this.cseEmploye.public_id
        });
    }

    toggleDisplayView() {
        this.showTransferView = !this.showTransferView;
    }

}
