import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'mcv-wallet-avatar',
    templateUrl: './wallet-avatar.component.html',
    styleUrls: ['./wallet-avatar.component.scss']
})
export class WalletAvatarComponent implements OnInit {
    @Input() walletId: string | number;
    @Input() color = 'primary';
    @Input() displayName = true;

    constructor() {
    }

    ngOnInit(): void {
    }

}
