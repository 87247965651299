import {Component, Inject, Input, OnInit} from '@angular/core';
import {CrmTableComponent} from '@crm/interfaces/CrmTableComponent';
import {Contribution, ContributionTopup} from '@mcv/core';
import {DataStore} from '@crm/app/store/services/store.service';
import {FormControl, FormGroup} from '@angular/forms';
import {ContributionTopupDataStore} from '@crm/app/store/store';

@Component({
    selector: 'mcv-contribution-topup-list',
    templateUrl: './contribution-topup-list.component.html',
    styleUrls: ['./contribution-topup-list.component.scss']
})
export class ContributionTopupListComponent extends CrmTableComponent<ContributionTopup> implements OnInit {

    displayedColumns: string[] = [
        'id',
        'amount',
        'state',
        'date_executed',
        'date_expected',
        'transaction',
        'transaction_state',
        'topup_state'
    ];

    constructor(@Inject(ContributionTopupDataStore) public store: DataStore<ContributionTopup>) {
        super();
    }

    @Input() set contribution(contribution: Contribution) {
        this.store.linkFilterForm(new FormGroup({
            contribution_id: new FormControl(contribution.id),
            withTopup: new FormControl(true)
        }));
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

}
