import {Component, Input} from '@angular/core';
import {CseEmploye, CseEmployeTopup} from '@mcv/core';

@Component({
    selector: 'mcv-cse-employe-topup-payout',
    templateUrl: './cse-employe-topup-payout.component.html',
    styleUrls: ['./cse-employe-topup-payout.component.scss']
})
export class CseEmployeTopupPayoutComponent {

    @Input() cseEmploye: CseEmploye;
    @Input() topup?: CseEmployeTopup;
}
