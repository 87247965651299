<div [nbSpinner]="syncing">
    <ng-container *ngIf="wallet$ | async as wallet">
        <div class="row align-items-center my-1">
            <div class="col">
                <span class="wallet--title__accent mr-2">IBAN :</span>
                <span class="mr-2">{{wallet.iban}}</span>
                <span class="wallet--title__accent mr-2">BIC :</span>
                <span>{{wallet.bic}}</span>
            </div>
        </div>
        <div class="row my-1">
            <div class="col">
                <span class="wallet--title__accent  mr-2">State</span>
                <span class="tag tag-bg-{{wallet | walletStateClass}}">{{wallet.walletStatus}}</span>
            </div>
        </div>
        <div class="row my-1">
            <div class="col">
                <span class="wallet--title__accent  mr-2">Balance autorisée</span>
                <span>{{wallet.authorizedBalance | money}}</span>
            </div>
        </div>
    </ng-container>
</div>
