<div class="d-flex align-items-center">
    <span *ngIf="displayName" class="mr-2 name--span__weight">
       <span
           *ngIf="displayId">#{{cseEmploye.id}}</span> {{cseEmploye?.lastname | uppercase}} {{cseEmploye?.firstname | titlecase}}
    </span>
    <button (click)="openDetail()"
            *ngIf="displayDetail"
            nbButton
            nbTooltip="Ouvrir la fiche employé"
            size="tiny"
            status="success">
        <nb-icon icon="eye" pack="far"></nb-icon>
    </button>
</div>
<div *ngIf="displayEmail" class="mt-1">
    <a href="mailto:{{cseEmploye.email}}">{{cseEmploye.email}}</a>
</div>
<div class="d-flex align-items-center justify-content-start mt-1">
    <div *ngIf="displayBalance" class="mr-2">
        <mcv-button-trz-balance [displayText]="false" [onlyIcon]="true"
                                [trz_walletId]="cseEmploye.trz_walletId"></mcv-button-trz-balance>
    </div>
    <div *ngIf="displayWallet" class="mr-2">
        <mcv-button-trz-wallet [displayText]="false" [onlyIcon]="true"
                               [trz_walletId]="cseEmploye.trz_walletId"></mcv-button-trz-wallet>
    </div>
    <div *ngIf="displayTrzName" class="mr-2">
        <mcv-button-trz-user [trz_userId]="cseEmploye.trz_userId"></mcv-button-trz-user>
    </div>
    <div *ngIf="displayCard" class="mr-2">
        <button nbButton nbTooltip="View Card info @MCV" size="tiny" status="info">
            <i class="far fa-credit-card fa-fw mr-2"></i>
            <span>@{{cseEmploye.cse_employe_card_id}}</span>
        </button>
    </div>
</div>
