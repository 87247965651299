/*
* font: https://stackoverflow.com/questions/42048836/change-a-tag-dynamically-angular-2
*/

import {AfterViewChecked, Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[replaceTag]'
})
export class ReplaceTagDirective implements AfterViewChecked {
    private _needUpdate = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {
    }

    private _tag = '';

    @Input('replaceTag')
    set tag(t: string) {

        this._tag = (!t) ? 'span' : t;
        this._needUpdate = true;

        const template = this.templateRef.elementRef.nativeElement.nextElementSibling;

        if (template) {
            this.templateRef.elementRef.nativeElement.parentNode.removeChild(template);
        }

        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
    }

    ngAfterViewChecked() {
        if (this._needUpdate) {
            this._updateTemplate();
            this._needUpdate = false;
        }
    }

    private _updateTemplate() {
        //Nullish Coalescing
        const template = this.templateRef.elementRef.nativeElement.nextElementSibling
            ?? this.templateRef.elementRef.nativeElement.previousElementSibling;

        if (template) {
            const r = document.createElement(this._tag);
            r.innerHTML = template.innerHTML;

            this.templateRef.elementRef.nativeElement.parentNode.replaceChild(r, template);
            this.templateRef.elementRef.nativeElement.innerHTML = template.innerHTML;
        }
    }

}
