import {animate, state, style, transition, trigger} from '@angular/animations';
import {HttpParams} from '@angular/common/http';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {DataStore} from '@crm/app/store/services/store.service';
import {TransactionStore} from '@crm/app/store/store';
import {CrmTableComponent} from '@crm/interfaces/CrmTableComponent';
import {TrzTransaction} from '@crm/interfaces/TrzTransaction';
import {PayinService} from '@mcv/coreservices';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-wallet-transaction-list',
    templateUrl: './wallet-transaction-list.component.html',
    styleUrls: ['./wallet-transaction-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class WalletTransactionListComponent extends CrmTableComponent<TrzTransaction> implements OnInit {
    @Input() trz_walletId: number;
    displayedColumns: string[] = [
        'transactionId',
        'walletDebitId',
        'walletCreditId',
        'transactionType',
        'description',
        'amount',
        'createdDate'
    ];
    expandedElement: any;
    ibanFullname = 'Indisponible';
    ibanAccount = 'Indisponible';
    bicAccount = 'Indisponible';

    constructor(@Inject(TransactionStore) public store: DataStore<TrzTransaction>,
                private payinService: PayinService) {
        super();
    }

    ngOnInit(): void {
        this.store.sorting = {active: 'createdDate', direction: 'desc'};
        super.ngOnInit();
        const httpParams = new HttpParams().set('walletId', this.trz_walletId.toString());
        if (httpParams) {
            this.loadPayin(httpParams);
        }
    }

    async loadPayin(httpParams) {
        try {
            await this.payinService
                .getList(1, 100, httpParams)
                .pipe(
                    map(r => {
                        if (r.data.length === 0) {
                            return;
                        }

                        const lastOne = r.data.length - 1;
                        this.ibanFullname = r.data[lastOne]?.ibanFullname ?? 'Compte non présent';
                        this.ibanAccount = r.data[lastOne]?.DbtrIBAN ?? 'IBAN non présent';
                        this.bicAccount = r.data[lastOne]?.ibanBic ?? 'BIC non présent';
                    })
                )
                .toPromise();
        } catch (e) {
            console.log(e);
        } finally {
        }
    }
}
