import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {McvnotifierService} from '@mcv/ui';
import {TrzToolsService} from '@crm/app/trz-tools/services/trz-tools.service';
import {map} from 'rxjs/operators';
import {format} from 'date-fns';

@Component({
    selector: 'mcv-trz-transaction',
    templateUrl: './trz-transaction.component.html',
    styleUrls: ['./trz-transaction.component.scss']
})
export class TrzTransactionComponent implements OnInit {
    @Input() transactionId: string;
    @Input() walletId: string;
    @Input() userId: string;
    searchForm: FormGroup = this.fb.group({
        walletId: [],
        userId: [],
        transactionId: [],
        createdDateFrom: [],
        createdDateTo: []
    });
    result: any;
    searching = false;

    constructor(private fb: FormBuilder,
                private activatedRoute: ActivatedRoute,
                private notifierService: McvnotifierService,
                private trzToolsService: TrzToolsService) {
    }

    ngOnInit(): void {
        if (!this.transactionId && !this.userId && !this.walletId) {
            this.readRouteParams();
        } else {
            this.readFromInput();
        }
    }

    async search() {
        try {
            this.searching = true;
            const formData = this.searchForm.value;
            formData['createdDateFrom'] = this.convertDateToTrzFormat(formData['createdDateFrom']);
            formData['createdDateTo'] = this.convertDateToTrzFormat(formData['createdDateTo']);
            this.result = await this.trzToolsService
                .searchTransaction(this.searchForm.value)
                .pipe(map(r => r.data))
                .toPromise();
        } catch (e) {
            this.result = null;
            this.notifierService.error(e);
        } finally {
            this.searching = false;
        }
    }

    convertDateToTrzFormat(formDate: Date) {
        return formDate ? format(formDate, 'yyyy-MM-ddTHH:mm:ssZ') : null;
    }

    private readFromInput() {
        this.searchForm.patchValue({
            userId: this.userId,
            walletId: this.walletId,
            transactionId: this.transactionId
        });
        this.search();
    }

    private readRouteParams() {
        if (this.activatedRoute.snapshot.queryParamMap.has('transactionId')) {
            this.searchForm.patchValue({transferId: this.activatedRoute.snapshot.queryParamMap.get('transactionId')});
            this.search();
        }
    }
}
