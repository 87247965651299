import {Component, Input, OnInit} from '@angular/core';
import {Cse} from '@mcv/core';

@Component({
    selector: 'mcv-cse-avatar',
    templateUrl: './cse-avatar.component.html',
    styleUrls: ['./cse-avatar.component.scss']
})
export class CseAvatarComponent implements OnInit {
    @Input() cse: Cse;
    @Input() displayTrzUser = false;
    @Input() displayTrzWallet = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
