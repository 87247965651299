import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AdminWalletService} from '@crm/app/shared/services/admin-wallet.service';
import {Wallet} from '@crm/interfaces/Wallet';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

@Component({
    selector: 'mcv-wallet-card',
    templateUrl: './wallet-card.component.html',
    styleUrls: ['./wallet-card.component.scss']
})
export class WalletCardComponent implements OnChanges {
    @Input() walletId: number;
    wallet$: Observable<Wallet>;
    syncing: any;

    constructor(private adminWalletService: AdminWalletService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.walletId) {
            this.wallet$ = this.loadData(this.walletId);
        }
    }

    loadData(walletId: number) {
        this.syncing = true;
        return this.adminWalletService
            .view(walletId.toString())
            .pipe(
                map(r => r.data),
                tap(_ => this.syncing = false)
            );
    }
}
