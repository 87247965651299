<form (ngSubmit)="search()" [formGroup]="searchForm">
    <div class="row">
        <div class="col-10">
            <div class="row">
                <div class="col-3 mb-3">
                    <input formControlName="transactionId"
                           fullWidth
                           nbInput
                           placeholder="Recherche par numéro de transaction"
                           type="search"/>
                </div>
                <div class="col-3">
                    <input formControlName="userId"
                           fullWidth
                           nbInput
                           placeholder="Utilisateur concerné"
                           type="search"/>
                </div>
                <div class="col-3">
                    <input formControlName="walletId"
                           fullWidth
                           nbInput
                           placeholder="Wallet concerné"
                           type="search"/>
                </div>
                <div class="col-3">
                    <input [nbDatepicker]="datepickerstart"
                           formControlName="createdDateFrom"
                           fullWidth
                           nbInput
                           placeholder="Date de début"
                           type="search"/>
                    <nb-datepicker #datepickerstart></nb-datepicker>
                </div>
                <div class="col-3">
                    <input [nbDatepicker]="datepickerend"
                           formControlName="createdDateTo"
                           fullWidth
                           nbInput
                           placeholder="Date de fin"
                           type="search"/>
                    <nb-datepicker #datepickerend></nb-datepicker>
                </div>
            </div>
        </div>
        <div class="col d-flex">
            <button [disabled]="searching"
                    [nbSpinner]="searching"
                    class="ml-auto"
                    nbButton
                    status="success"
                    type="submit">Rechercher
            </button>
            <button class="ml-2" nbButton status="primary" type="reset">Effacer</button>
        </div>
    </div>
</form>
<div *ngIf="result" class="row mt-4">
    <div class="col">
        <ngx-json-viewer [json]="result"></ngx-json-viewer>
    </div>
</div>
