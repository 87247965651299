<table [dataSource]="store"
       cdk-table
       class="table table-borderless table-bigrow table-bigrow-hover"
       matSort
       matSortActive="id"
       matSortDirection="asc"
       matSortDisableClear
       multiTemplateDataRows>
    <tr *cdkHeaderRowDef="displayedColumns" cdk-header-row></tr>
    <tr *cdkRowDef="let element; let row; columns: displayedColumns;" cdk-row></tr>

    <tr *matNoDataRow class="mat-row">
        <td class="mat-cell text-center" colspan="7">
            Pas de topup de contributions
        </td>
    </tr>

    <ng-container cdkColumnDef="id">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="id">#</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width d-flex">
            #{{element.id}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="amount">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="amount">Montant</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width"
            style="word-break: keep-all">
            {{element.amount}} €
        </td>
    </ng-container>
    <ng-container cdkColumnDef="state">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="state">Status</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width"
            style="word-break: keep-all">
            {{element.state}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="date_executed">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="date_executed">Date d'éxécution</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width"
            style="word-break: keep-all">
            {{element.date_executed | date:'short'}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="date_expected">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="date_expected">Date attendu</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width"
            style="word-break: keep-all">
            {{element.date_expected | date:'short'}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="transaction">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="transaction">Transaction ID</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width"
            style="word-break: keep-all">
            <ng-container *ngIf="element.cse_employe_topup?.transaction !== null">
                <a href="https://merchant.hipay-tpp.com/maccount/75929/transaction/detail/index/trxid/{{element.cse_employe_topup.transaction}}/token/60dc80a16a928"
                   target="_blank">
                    {{element.cse_employe_topup?.transaction}}&nbsp;
                    <i class="fas fa-external-link-square-alt"></i>
                </a>
            </ng-container>
        </td>
    </ng-container>
    <ng-container cdkColumnDef="transaction_state">
        <th *cdkHeaderCellDef cdk-header-cell>Transaction Status</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width"
            style="word-break: keep-all">
            {{element.cse_employe_topup?.transaction_state}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="topup_state">
        <th *cdkHeaderCellDef cdk-header-cell>Topup Status</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width"
            style="word-break: keep-all">
            {{element.cse_employe_topup?.state}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="actions">
        <th *cdkHeaderCellDef cdk-header-cell>Action</th>
        <td *cdkCellDef="let element" cdk-cell class="cell--action__width">
        </td>
    </ng-container>
</table>
<nb-spinner *ngIf="store.loading$ | async"></nb-spinner>
<br/>
<ng-container *ngIf="paging$ | async as pagingInfo">
    <mat-paginator (page)="changePage($event)"
                   *ngIf="pagingInfo.count > limit"
                   [color]="'primary'"
                   [length]="pagingInfo.count"
                   [pageIndex]="pagingInfo.current_page - 1"
                   [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="limit">
    </mat-paginator>
</ng-container>
