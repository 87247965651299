<button (click)="preview(document)"
        [disabled]="previewing"
        [nbSpinner]="previewing"
        class="mr-2 my-2"
        fullWidth
        nbButton
        nbTooltip="Prévisualiser le document"
        size="small"
        status="primary"
        type="button">
    Prévisualiser <i class="far fa-eye fa-fw ml-2"></i>
</button>
<ng-container *ngIf="document.state === 'draft'">
    <button (click)="check(document)"
            [disabled]="submitting"
            [nbSpinner]="submitting"
            class="mr-2 my-2"
            fullWidth
            nbButton
            nbTooltip="Marquer le document comme vérifié"
            size="small"
            status="success"
            type="button">
        Marquer comme vérifié <i class="fas fa-check fa-fw ml-2"></i>
    </button>
    <button (click)="askRefused(document,dialog)"
            [disabled]="refusing"
            [nbSpinner]="refusing"
            class="mr-2 my-2"
            fullWidth
            nbButton
            nbTooltip="Refuser le document"
            size="small"
            status="danger"
            type="button">
        Refuser <i class="fas fa-times fa-fw"></i>
    </button>
    <button (click)="askDelete()"
            [disabled]="deleting"
            [nbSpinner]="deleting"
            class="mr-2 my-2"
            fullWidth
            nbButton
            nbTooltip="Supprimer le document"
            size="small"
            status="danger"
            type="button">
        Supprimer le document <i class="fas fa-trash-alt fa-fw"></i>
    </button>
</ng-container>
<ng-container *ngIf="document.state === 'submit'">
    <button class="mr-2 my-2" fullWidth nbButton size="small" status="warning" type="button">
        Envoyé le {{document.date_sent | date:'short'}}
    </button>
</ng-container>
<ng-container *ngIf="document.state === 'refused'">
    <button class="mr-2 my-2" fullWidth nbButton size="small" status="danger" type="button">
        Refusé le {{document.date_update | date:'short'}}
    </button>
</ng-container>
<ng-container *ngIf="document.state === 'ok'" size="small">
    <button class="mr-2 my-2" fullWidth nbButton status="success" type="button">
        Document validé
    </button>
</ng-container>
<ng-container *ngIf="document.state === 'check'" size="small">
    <button class="mr-2 my-2" fullWidth nbButton status="warning" type="button">
        Document vérifié
    </button>
</ng-container>
<ng-template #dialog let-data let-ref="dialogRef">
    <form (ngSubmit)="refuse(data,ref)" [formGroup]="documentForm">
        <nb-card style="width: 50vw">
            <nb-card-header>Refuser le document KYC</nb-card-header>
            <nb-card-body>
                <label>Indiquez la raison du refus :</label>
                <input formControlName="kyc_message_mcv"
                       fullWidth
                       nbInput
                       required
                       type="text"/>
                <val-errors controlName="kyc_message_mcv"></val-errors>
            </nb-card-body>
            <nb-card-footer>
                <button (click)="ref.close()" class="mr-2" nbButton status="basic" type="button">Annuler</button>
                <button [disabled]="!documentForm.valid" nbButton status="primary" type="submit">
                    Refuser le document
                </button>
            </nb-card-footer>
        </nb-card>
    </form>
</ng-template>
