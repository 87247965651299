<form (ngSubmit)="search()" [formGroup]="searchForm">
    <div class="row">
        <div class="col">
            <input formControlName="userId"
                   fullWidth
                   nbInput
                   placeholder="Recherche par numéro d'utilisateur Treezor"
                   type="search"/>
        </div>
        <div class="col">
            <input formControlName="name"
                   fullWidth
                   nbInput
                   placeholder="Recherche par nom ou prénom"
                   type="search"/>
        </div>
        <div class="col">
            <input formControlName="legalName"
                   fullWidth
                   nbInput
                   placeholder="Recherche par nom du CSE"
                   type="search"/>
        </div>
        <div class="col d-flex">
            <button [disabled]="searching" [nbSpinner]="searching" class="ml-auto" nbButton status="success"
                    type="submit">Rechercher
            </button>
            <button class="ml-2" nbButton status="primary" type="reset">Effacer</button>
        </div>
    </div>
</form>
<div *ngIf="result" class="row mt-4">
    <div class="col">
        <ngx-json-viewer [json]="result"></ngx-json-viewer>
    </div>
</div>
