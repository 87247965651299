<div *ngIf="lstGroup && lstGroup.length > 0; else msgEmpty" [ngClass]="theme" id="timeline-albe">
    <!-- A exibição do menu depende da definição de visibilidade do agrupador. -->
    <nav *ngIf="showGroup && showMenu">
        <a *ngFor="let group of lstGroup;" [attr.href]="('#Y'+ group.Key)">{{group.Key}}</a>
    </nav>

    <ng-container *ngFor="let group of lstGroup; index as i1; last as l1;">
        <section>
            <header *ngIf="showGroup" [attr.id]="('Y'+ group.Key)" class="group">{{group.Key}}</header>
            <div *ngFor="let element of group.Elements; index as i2; last as l2;" class="item">
                <div class="badge {{element.class}}">
                    <i *ngIf="element.icon" class="material-icons">{{element.icon}}</i>
                    <span *ngIf="!element.icon">{{element.datetime | date: 'dd'}}</span>
                </div>
                <article [class.inverted]="(((i1 + i2) % 2) != 0)">
                    <ng-container *ngIf="!itemTemplate; else displayTemplate">
                        <header *ngIf="element.header" [innerHTML]="element.header | safeHtml">
                        </header>
                        <div class="body">
                            <span>{{element.datetime | date: formatDate}}</span>
                            <ng-container *ngFor="let itemBody of element.body;">
                                <div *replaceTag="itemBody.tag" [innerHTML]="itemBody.content | safeHtml">
                                </div>
                            </ng-container>
                        </div>
                        <footer *ngIf="element.footer" [innerHTML]="element.footer | safeHtml">
                        </footer>
                    </ng-container>
                    <ng-template #displayTemplate>
                        <div *ngTemplateOutlet="itemTemplate;context:{data:element}"></div>
                    </ng-template>
                </article>
                <div *ngIf="l1 && l2" class="badge {{element.class}} last">
                </div>
            </div>
        </section>
    </ng-container>

</div>
<ng-template #msgEmpty>
    {{emptyContent}}
</ng-template>
