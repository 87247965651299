<div *ngIf="virementBeneficiary?.state === 'activated' && !loading">
    <form (ngSubmit)="doRefund()" [formGroup]="payoutForm">
        <formly-form [fields]="refundFields"
                     [form]="payoutForm"
                     [model]="virementBeneficiary"></formly-form>
        <br/>
        <div class="row m-2">
            <div class="col">Montant maximum du virement: <b>{{maxAmount}}</b> EURO</div>
            <div *ngIf="topup?.amount!==null" class="col">Montant du topup : <b>{{topup.amount}}</b> EURO</div>
            <div class="col">Montant restant après virement : <b>{{currentBalance - payoutForm.value.amount}} EURO</b>
            </div>
        </div>
        <button
            [disabled]="(loading) || !payoutForm.valid || payoutForm.value.amount < 1 || payoutForm.value.amount > maxAmount"
            [nbSpinner]="loading"
            fullWidth
            nbButton
            status="primary"
            type="submit">
            Virer le montant vers le bénéficiaire
        </button>
    </form>
</div>
