import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {CrmTableComponent} from '@crm/interfaces/CrmTableComponent';
import {Contribution, CseEmploye} from '@mcv/core';
import {ContributionDataStore} from '@crm/app/store/store';
import {DataStore} from '@crm/app/store/services/store.service';
import {FormControl, FormGroup} from '@angular/forms';
import {
    ContributionTopupListComponent
} from '@crm/app/contributions/ui/contribution-topup-list/contribution-topup-list.component';
import {WindowManagerService} from '@crm/app/window-manager/services/window-manager.service';
import {MatSort, MatSortable} from '@angular/material/sort';

@Component({
    selector: 'mcv-contribution-list',
    templateUrl: './contribution-list.component.html',
    styleUrls: ['./contribution-list.component.scss']
})
export class ContributionListComponent extends CrmTableComponent<Contribution> implements OnInit {

    displayedColumns: string[] = [
        'id',
        'contribution',
        'payed',
        'remaining_due',
        'state',
        'type',
        'nb_recurrence',
        'actions'
    ];

    @ViewChild(MatSort) sort: MatSort;

    expandedElement: any;

    constructor(@Inject(ContributionDataStore) public store: DataStore<Contribution>,
                private windowService: WindowManagerService) {
        super();
    }

    @Input() set cseEmploye(cseEmploye: CseEmploye) {
        this.store.linkFilterForm(new FormGroup({
            cse_employe_id: new FormControl(cseEmploye.id),
            withTopup: new FormControl(true)
        }));
    }

    topup(element: Contribution) {
        const context = {
            contribution: {...element}
        };
        this.windowService.open(ContributionTopupListComponent, {
            context: context,
            title: 'Liste des topups de contributions'
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.store.sorting = {active: 'id', direction: 'desc'};
        this.sort.sort(({id: 'id', start: 'desc'}) as MatSortable);
    }

}
