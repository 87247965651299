import {Component, Input, OnInit} from '@angular/core';
import {CseEmploye, CseEmployeTopup, VirementBeneficiary} from '@mcv/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {FormBuilder} from '@angular/forms';
import {VirementBeneficiaryService} from '@mcv/coreservices';
import {McvnotifierService} from '@mcv/ui';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-cse-employe-topup-beneficiary-list',
    templateUrl: './cse-employe-topup-beneficiary-list.component.html',
    styleUrls: ['./cse-employe-topup-beneficiary-list.component.scss']
})
export class CseEmployeTopupBeneficiaryListComponent implements OnInit {

    @Input() cseEmploye: CseEmploye;
    @Input() topup?: CseEmployeTopup;
    loading = true;
    showNewAccount = false;
    virementBeneficiaries: [VirementBeneficiary];

    constructor(private modalService: BsModalService,
                private fb: FormBuilder,
                private virementBeneficiaryService: VirementBeneficiaryService,
                private notifierService: McvnotifierService) {
    }

    ngOnInit() {
        this.loadData();
    }

    async loadData(): Promise<void> {
        try {
            this.loading = true;
            const array = await this.virementBeneficiaryService
                .search('cse_employe_id', '' + this.cseEmploye.id)
                .pipe(map(r => r.data))
                .toPromise();
            this.virementBeneficiaries = array.sort((a: VirementBeneficiary, b: VirementBeneficiary) => {
                return Date.parse(b.date_create) - Date.parse(a.date_create);
            });
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.loading = false;
        }
    }

    toggleNewAccount(): void {
        this.showNewAccount = !this.showNewAccount;
    }

    itemAdded(newItem: boolean) {
        this.showNewAccount = false;
        this.loadData();
    }

}
