import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PreviewDocComponent } from '@crm/app/shared/ui/preview-doc/preview-doc.component';
import { DataStore } from '@crm/app/store/services/store.service';
import { CseDocumentDataStore } from '@crm/app/store/store';
import { WindowManagerService } from '@crm/app/window-manager/services/window-manager.service';
import { CseDocument } from '@mcv/core';
import { CseDocumentService } from '@mcv/coreservices';
import { McvnotifierService } from '@mcv/ui';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalConfirmationComponent } from '@crm/app/shared/ui/modal-confirmation/modal-confirmation.component';

@Component({
    selector: 'mcv-cse-document-synthesis',
    templateUrl: './cse-document-synthesis.component.html',
    styleUrls: ['./cse-document-synthesis.component.scss']
})
export class CseDocumentSynthesisComponent implements OnInit, OnDestroy {
    @Input() displayName = false;
    @Input() document: CseDocument;
    @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

    documentForm: FormGroup = this.fb.group({
        kyc_message_mcv: [null, [Validators.required]]
    });
    deleting = false;
    previewing = false;
    submitting = false;
    refusing = false;
    reviewing = false;
    reason: string;
    subscription: Subscription = new Subscription();

    constructor(@Inject(CseDocumentDataStore) public store: DataStore<CseDocument>,
                private cseDocumentService: CseDocumentService,
                private fb: FormBuilder,
                private dialogService: NbDialogService,
                private windowManagerService: WindowManagerService,
                private notifierService: McvnotifierService) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    async delete(element: CseDocument) {
        try {
            this.deleting = true;
            await this.cseDocumentService.delete(element.public_id)
                .toPromise();
            this.notifierService.success('Le document a été supprimé');
            this.refresh.emit(true);
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.deleting = false;
        }
    }

    async preview(element: CseDocument) {
        try {
            this.previewing = true;
            const data: Blob = await this.cseDocumentService.getPreviewData(element.id)
                .toPromise();
            const objectUrl = URL.createObjectURL(data);
            const window = this.windowManagerService.open(PreviewDocComponent, {
                title: `Prévisualisation ${element.filename}`,
                windowClass: 'large',
                context: { srcUrl: objectUrl }
            });
            this.subscription.add(window.onClose.subscribe(_ => URL.revokeObjectURL(objectUrl)));
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.previewing = false;
        }
    }

    askRefused(element: CseDocument, dialog: TemplateRef<any>) {
        this.dialogService.open(dialog, { context: element });
    }

    async refuse(element: CseDocument, dialog: NbDialogRef<any>) {
        try {
            this.refusing = true;
            await this.cseDocumentService
                .edit(element.public_id, {
                    state: 'refused',
                    kyc_message_mcv: this.documentForm.value.kyc_message_mcv
                })
                .pipe(map(r => r.data))
                .toPromise();
            this.document.state = 'refused';
            this.document.kyc_message_mcv = this.documentForm.value.kyc_message_mcv;
            this.notifierService.success('Le document a été refusé');
            this.refresh.emit(true);
            dialog.close();
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.refusing = false;
        }
    }

    async check(document: CseDocument) {
        try {
            this.submitting = true;
            await this.cseDocumentService
                .edit(document.public_id, { state: 'check' })
                .toPromise();
            this.document.state = 'check';
            this.notifierService.success('Le document a été marqué comme vérifié');
            this.refresh.emit(true);
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.submitting = false;
        }
    }

    async askDelete() {
        this.subscription.add(
            this.dialogService.open(ModalConfirmationComponent, {
                context: {
                    title: 'Confirmation de la suppression du document KYC',
                    confirmButtonLabel: 'Oui, supprimer',
                    cancelButtonLabel: 'Non, c\'est une erreur',
                    confirmText: 'Êtes-vous sûr de vouloir continuer ?'
                }
            })
                .onClose
                .subscribe(r => {
                    if (r) {
                        this.delete(this.document);
                    }
                })
        );
    }
}
