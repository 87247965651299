<div class="p-2">
    <button (click)="toggleNewAccount()"
            fullWidth
            nbButton
            status="info"
            type="submit">
        {{ showNewAccount ? 'Fermer' : 'Ajouter un nouveau compte' }}
    </button>
</div>
<div *ngIf="showNewAccount">
    <mcv-cse-emploue-topup-payout-beneficiary-detail (beneficiaryAdded)="itemAdded($event)" [cseEmploye]="cseEmploye"
                                                     [topup]="topup"
                                                     [virementBeneficiary]="null"></mcv-cse-emploue-topup-payout-beneficiary-detail>
</div>
<div *ngIf="!loading; else loadingView">
    <div *ngFor="let virementBeneficiary of virementBeneficiaries">
        <mcv-cse-emploue-topup-payout-beneficiary-detail (beneficiaryAdded)="itemAdded($event)"
                                                         [cseEmploye]="cseEmploye" [topup]="topup"
                                                         [virementBeneficiary]="virementBeneficiary"></mcv-cse-emploue-topup-payout-beneficiary-detail>
        <hr/>
    </div>
</div>

<ng-template #loadingView>
    <div class="w-100 h-100 d-flex align-items-center justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>

