/**
 * This Decorator will memoize a method
 */
import {Observable, Subject} from 'rxjs';
import {publishReplay, refCount} from 'rxjs/operators';

export const cacheBurster$ = new Subject();

export function memo() {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        let cached = false;
        cacheBurster$.subscribe(v => {
            cached = false;
        });
        const method = descriptor.value; // references the method being decorated
        const cacheMember = propertyKey + 'CacheMember';
        // the Observable function
        if (!descriptor.value) {
            throw new Error('use MemoizeDecorator only on services methods');
        }

        descriptor.value = function (...args) {
            const key = cacheMember + args[0];
            if (!target[key] || !cached) {
                const returnedObservable = method.apply(this, args);
                if (!(returnedObservable instanceof Observable)) {
                    throw new Error('method decorated with Memoized Decorator must return Observable');
                }

                target[key] = returnedObservable.pipe(
                    publishReplay(),
                    refCount()
                );
                cached = true;
            }

            return target[key];
        };
    };
}
