<div class="d-flex align-items-center">
    <span *ngIf="displayName" class="mr-2 name--span__weight">
       <span *ngIf="displayId" nbTooltip="{{cseUser.id}} @mcv">#{{cseUser.id}}</span>
        {{cseUser?.lastname | uppercase}} {{cseUser?.firstname | titlecase}}
    </span>
    <button (click)="openDetail()"
            *ngIf="displayDetail"
            nbButton
            nbTooltip="Ouvrir le détail"
            size="tiny"
            status="success">
        <nb-icon icon="eye" pack="far"></nb-icon>
    </button>
</div>
<div *ngIf="displayEmail && cseUser.email" class="mt-1">
    <a href="mailto:{{cseUser.email}}">{{cseUser.email}}</a>
</div>
<div *ngIf="displayPhone && cseUser.mobile">
    Téléphone : <a href="tel:{{cseUser.mobile}}">{{cseUser.mobile}}</a>
</div>
<div *ngIf="displayTrzName && cseUser.trz_userId" class="d-flex align-items-center justify-content-start">
    <mcv-button-trz-user [trz_userId]="cseUser.trz_userId"></mcv-button-trz-user>
</div>
