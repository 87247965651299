<table [dataSource]="store"
       cdk-table
       class="table table-borderless table-bigrow table-bigrow-hover"
       matSort
       matSortActive="createdDate"
       matSortDirection="desc"
       matSortDisableClear
       multiTemplateDataRows>
    <tr *cdkHeaderRowDef="displayedColumns" cdk-header-row></tr>
    <tr *cdkRowDef="let row; columns: displayedColumns;" cdk-row></tr>

    <tr *matNoDataRow class="mat-row">
        <td class="mat-cell text-center" colspan="7">
            Pas de transactions
        </td>
    </tr>
    <tr *cdkRowDef="let row; columns: ['expandedDetail']" cdk-row class="example-detail-row"></tr>

    <ng-container cdkColumnDef="expandedDetail">
        <td *cdkCellDef="let element" [attr.colspan]="displayedColumns.length" cdk-cell>
            <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                 class="example-element-detail">
                <ul>
                    <li>Compte : {{ibanFullname}}</li>
                    <li>IBAN : {{ibanAccount}}</li>
                    <li>BIC : {{bicAccount}}</li>
                </ul>
                <pre>{{element | json}}</pre>
            </div>
        </td>
    </ng-container>

    <ng-container cdkColumnDef="transactionId">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="transactionId">#</th>
        <td *cdkCellDef="let element" cdk-cell class="table__id--width table__id--bold d-flex">
            <nb-icon (click)="expandedElement = expandedElement === element ? null : element"
                     class="expansion-indicator mr-1 {{expandedElement === element?'toggled':''}}"
                     icon="chevron-down-outline"></nb-icon>
            {{element.transactionId}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="description">
        <th *cdkHeaderCellDef cdk-header-cell>Description</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width"
            style="word-break: keep-all">
            {{element.description}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="walletDebitId">
        <th *cdkHeaderCellDef cdk-header-cell>De</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__wallet--width {{element.walletDebitId === trz_walletId.toString() ? 'text-success':''}}"
            style="word-break: keep-all">
            <span *ngIf="element.walletDebitId">
                <mcv-wallet-avatar [color]="element.walletDebitId === trz_walletId.toString() ? 'success':'primary'"
                                   [walletId]="element.walletDebitId"></mcv-wallet-avatar>
            </span>
        </td>
    </ng-container>
    <ng-container cdkColumnDef="walletCreditId">
        <th *cdkHeaderCellDef cdk-header-cell>Vers</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__wallet--width {{element.walletCreditId === trz_walletId.toString() ? 'text-success':''}}"
            style="word-break: keep-all">
            <span *ngIf="element.walletCreditId">
                <mcv-wallet-avatar [color]="element.walletCreditId === trz_walletId.toString() ? 'success':'primary'"
                                   [walletId]="element.walletCreditId"></mcv-wallet-avatar>
            </span>
        </td>
    </ng-container>
    <ng-container cdkColumnDef="transactionType">
        <th *cdkHeaderCellDef cdk-header-cell>Type</th>
        <td *cdkCellDef="let element" cdk-cell class="table__ordername--width"
            style="word-break: keep-all">
            {{element.transactionType}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="amount">
        <th *cdkHeaderCellDef cdk-header-cell class="table__amount--width">Montant</th>
        <td *cdkCellDef="let element" cdk-cell class="table__amount--width">
            <span class="{{element.walletDebitId === trz_walletId.toString() ? 'text-danger' : 'text-success'}}">
                {{element.walletDebitId === trz_walletId.toString() ? '- ' : ''}}{{element.amount | money}}
            </span>
        </td>
    </ng-container>
    <ng-container cdkColumnDef="createdDate">
        <th *cdkHeaderCellDef cdk-header-cell class="table__date--align" mat-sort-header="createdDate">Date</th>
        <td *cdkCellDef="let element" cdk-cell class="table__date--align">
            {{element.createdDate | date:'short'}}
        </td>
    </ng-container>

    <ng-container cdkColumnDef="actions">
        <th *cdkHeaderCellDef cdk-header-cell>Action</th>
        <td *cdkCellDef="let element" cdk-cell class="cell--action__width">

        </td>
    </ng-container>
</table>
<nb-spinner *ngIf="store.loading$ | async"></nb-spinner>

<br/>
<ng-container *ngIf="paging$ | async as pagingInfo">
    <mat-paginator (page)="changePage($event)"
                   *ngIf="pagingInfo.count > limit"
                   [color]="'primary'"
                   [length]="pagingInfo.count"
                   [pageIndex]="pagingInfo.current_page - 1"
                   [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="limit">
    </mat-paginator>
</ng-container>
