<table [dataSource]="store"
       cdk-table
       class="table table-borderless table-bigrow table-bigrow-hover"
       matSort
       matSortActive="name"
       matSortDirection="asc"
       matSortDisableClear
       multiTemplateDataRows>
    <tr *cdkHeaderRowDef="displayedColumns" cdk-header-row></tr>
    <tr *cdkRowDef="let element; let row; columns: displayedColumns;" cdk-row></tr>

    <tr *matNoDataRow class="mat-row">
        <td class="mat-cell text-center" colspan="7">
            Pas de contributions
        </td>
    </tr>

    <ng-container cdkColumnDef="id">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="id">#</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width d-flex">
            #{{element.id}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="state">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="state">Status</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width"
            style="word-break: keep-all">
            {{element.state}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="contribution">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="contribution">Montant total</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width"
            style="word-break: keep-all">
            {{element.contribution}} €
        </td>
    </ng-container>
    <ng-container cdkColumnDef="payed">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="payed">Payé</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width"
            style="word-break: keep-all">
            {{element.payed}} €
        </td>
    </ng-container>
    <ng-container cdkColumnDef="remaining_due">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="remaining_due">Montant restant</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width"
            style="word-break: keep-all">
            {{element.remaining_due}} €
        </td>
    </ng-container>
    <ng-container cdkColumnDef="type">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="type">Type</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width"
            style="word-break: keep-all">
            {{element.type}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="nb_recurrence">
        <th *cdkHeaderCellDef cdk-header-cell>Mode</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width"
            style="word-break: keep-all">
            {{element.is_recurring === true ? 'Paiements récurrents' : 'Paiement(s) manuel(s)'}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="actions">
        <th *cdkHeaderCellDef cdk-header-cell>Action</th>
        <td *cdkCellDef="let element" cdk-cell class="cell--action__width">
            <button (click)="topup(element)"
                    class="ml-2"
                    nbButton size="small">
                Topups
            </button>
        </td>
    </ng-container>
</table>
<nb-spinner *ngIf="store.loading$ | async"></nb-spinner>
<br/>
<ng-container *ngIf="paging$ | async as pagingInfo">
    <mat-paginator (page)="changePage($event)"
                   *ngIf="pagingInfo.count > limit"
                   [color]="'primary'"
                   [length]="pagingInfo.count"
                   [pageIndex]="pagingInfo.current_page - 1"
                   [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="limit">
    </mat-paginator>
</ng-container>
