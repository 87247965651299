<form [formGroup]="documentForm">
    <div class="text-right">
        <button *ngIf="items"
                [disabled]="saving"
                [nbContextMenu]="items"
                [nbSpinner]="saving"
                nbButton
                nbContextMenuTag="{{tag}}"
                type="button">
            Enregistrer un nouveau document
        </button>
    </div>
</form>
<div #filePicker (filePick)="uploadDoc($event,documentTypeId)" ngxFilePicker></div>
