import {Component, Input, OnInit} from '@angular/core';
import {CseEmployeDetailComponent} from '@crm/app/cse-employes/ui/cse-employe-detail/cse-employe-detail.component';
import {WindowManagerService} from '@crm/app/window-manager/services/window-manager.service';
import {CseEmploye} from '@mcv/core';

@Component({
    selector: 'mcv-cse-employe-avatar',
    templateUrl: './cse-employe-avatar.component.html',
    styleUrls: ['./cse-employe-avatar.component.scss']
})
export class CseEmployeAvatarComponent implements OnInit {
    @Input() cseEmploye: CseEmploye;
    @Input() displayName = true;
    @Input() displayId = false;
    @Input() displayTrzName = true;
    @Input() displayEmail = false;
    @Input() displayWallet = false;
    @Input() displayCard = false;
    @Input() displayBalance = false;
    @Input() displayDetail = false;

    constructor(private windowService: WindowManagerService) {
    }

    ngOnInit(): void {
    }

    openDetail() {
        this.windowService.open(CseEmployeDetailComponent, {
            windowClass: 'large',
            title: `Détail employé #${this.cseEmploye.id}`,
            context: {cse_employe_public_id: this.cseEmploye.public_id}
        }
        );
    }
}
