import {Pipe, PipeTransform} from '@angular/core';
import {CseDocument} from '@mcv/core';

@Pipe({
    name: 'cseDocumentTreezorComment'
})
export class CseDocumentTreezorCommentPipe implements PipeTransform {

    transform(value: CseDocument, ...args: unknown[]): unknown {
        let output = '';
        if (value.kyc_message_mcv !== null) {
            output += '<b>MCV raison:</b>';
            output += '<ul>';
            output += '<li>msg: ' + value.kyc_message_mcv + '</li>';
            output += '</ul>';
        }
        if (value.state === 'refused') {
            output += '<b>DOCUMENT:</b>';
            output += '<ul>';
            output += '<li>code: ' + value.trz_codeStatus + '</li>';
            output += '<li>info: ' + value.trz_informationStatus + '</li>';
            output += '<li>doc: ' + value.trz_documentStatus + '</li>';
            output += '</ul>';
        }
        return output;
    }

}
