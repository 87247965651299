import {Component, Input, OnInit} from '@angular/core';
import {WindowManagerService} from '@crm/app/window-manager/services/window-manager.service';
import {NbComponentSize} from '@nebular/theme/components/component-size';
import {TrzUserComponent} from '@crm/app/trz-tools/ui/trz-user/trz-user.component';

@Component({
    selector: 'mcv-button-trz-user',
    templateUrl: './button-trz-user.component.html',
    styleUrls: ['./button-trz-user.component.scss']
})
export class ButtonTrzUserComponent implements OnInit {
    @Input() trz_userId: number;
    @Input() onlyIcon = true;
    @Input() displayText = true;
    @Input() size: NbComponentSize = 'tiny';

    constructor(private windowService: WindowManagerService) {
    }

    ngOnInit(): void {
    }

    openTrzUser() {
        this.windowService.open(TrzUserComponent, {
            context: {userId: this.trz_userId},
            title: `User #${this.trz_userId}`
        });
    }
}
