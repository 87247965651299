import {Pipe, PipeTransform} from '@angular/core';
import {CseDocument} from '@mcv/core';

@Pipe({
    name: 'cseDocumentStatut'
})
export class CseDocumentStatutPipe implements PipeTransform {

    transform(value: CseDocument, ...args: unknown[]): string {
        let output = '';
        if (value.state !== null) {
            output += '<div class="p-2">';
            output += `<span class="tag tag-bg-${this.getClass(value.state, true)}">`;
            output += `MCV: ${this.getLabel(value.state, true)}`;
            if (value.kyc_message_mcv !== null) {
                output += `<div class="small">${value.kyc_message_mcv}</div>`;
            }
            output += '</span>';
            output += '</div>';
        }
        return output;
    }

    private getLabel(value: string, fromMCV: boolean): string {
        switch (value) {
        case 'draft':
            return 'Brouillon';
        case 'submit':
            return 'Envoyé';
        case 'cancel':
            return 'Annulé';
        case 'ok':
            return 'Validé';
        case 'check':
            return fromMCV === true ? 'Validé' : 'En attente';
        case 'refused':
            return 'Refusé';
        case 'error':
            return 'En erreur';
        case 'deleted':
            return 'supprimé';
        default:
            return '';
        }
    }

    private getTrzStatut(value: CseDocument): string {
        switch (value.trz_documentStatus) {
        case 'PENDING':
            return 'En examen';
        case 'CANCELED':
            return 'Annulé';
        case 'REFUSED':
            return 'Refusé';
        case 'VALIDATED':
            return 'Accepté';
        }
    }

    private getClass(value: string, fromMCV: boolean): string {
        switch (value) {
        case 'draft':
            return 'primary';
        case 'submit':
            return 'warning';
        case 'deleted':
            return 'warning';
        case 'cancel':
            return 'danger';
        case 'ok':
            return 'success';
        case 'check':
            return fromMCV === true ? 'success' : 'warning';
        case 'refused':
            return 'danger';
        case 'error':
            return 'danger';
        default:
            return '';
        }
    }
}
