import {Pipe, PipeTransform} from '@angular/core';
import {AdminWalletService} from '@crm/app/shared/services/admin-wallet.service';
import {memo} from '@crm/app/utils/memoize';
import {Wallet} from '@crm/interfaces/Wallet';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Pipe({
    name: 'walletFetcher'
})
export class WalletFetcherPipe implements PipeTransform {

    constructor(private adminWalletService: AdminWalletService) {
    }

    @memo()
    transform(walletId: number, ...args: any[]): Observable<Wallet | null> {
        return this.adminWalletService
            .view(walletId.toString())
            .pipe(map(r => r.data));
    }
}
