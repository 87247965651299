<nb-card>
    <nb-card-header>{{title}}</nb-card-header>
    <nb-card-body>
        <label>
            {{confirmText}}
        </label>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-end">
        <button (click)="ref.close(false)"
                nbButton
                status="primary"
                type="button">
            {{cancelButtonLabel}}
        </button>
        <button (click)="ref.close(true)"
                class="ml-2"
                nbButton
                status="danger"
                type="button">
            {{confirmButtonLabel}}
        </button>
    </nb-card-footer>
</nb-card>
