<nb-accordion>
    <nb-accordion-item>
        <nb-accordion-item-header>Filtre</nb-accordion-item-header>
        <nb-accordion-item-body>
            <form [formGroup]="searchFilter">
                <div class="row">
                    <div class="col-2">
                        <label>Type de transaction</label>
                        <nb-select
                            formControlName="transactionType" fullWidth multiple>
                            <nb-option [value]="'Payin'">Payin</nb-option>
                            <nb-option [value]="'Payout'">Payout</nb-option>
                            <nb-option [value]="'Transfer'">Transfert</nb-option>
                            <nb-option [value]="'SCTR Inst'">Transfert Instant</nb-option>
                        </nb-select>
                    </div>
                    <div class="col-2">
                        <label>Date de début</label>
                        <mcv-admin-datepicker [format]="'YYYY-MM-DD HH:mm:ss'"
                                              [openOnFocus]="true"
                                              formControlName="startDate"></mcv-admin-datepicker>
                    </div>
                    <div class="col-2">
                        <label>Date de fin</label>
                        <mcv-admin-datepicker [format]="'YYYY-MM-DD HH:mm:ss'"
                                              [openOnFocus]="true"
                                              formControlName="endDate"></mcv-admin-datepicker>
                    </div>
                    <div *ngIf="displayWalletFilter" class="col-2">
                        <label>Numéro de wallet</label>
                        <input formControlName="walletId" fullWidth nbInput type="search"/>
                    </div>
                    <div class="col-2">
                        <label>Numéro de transaction</label>
                        <input formControlName="transactionId" fullWidth nbInput type="search"/>
                    </div>
                </div>
            </form>
        </nb-accordion-item-body>
    </nb-accordion-item>
</nb-accordion>
