import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
    selector: 'mcv-modal-confirmation',
    templateUrl: './modal-confirmation.component.html',
    styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent implements OnInit {
    @Input() title: string;
    @Input() confirmText: string;
    @Input() confirmButtonLabel: string;
    @Input() cancelButtonLabel: string;

    constructor(public ref: NbDialogRef<any>) {
    }

    ngOnInit(): void {
    }

}
