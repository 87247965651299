import {Component, Input, OnInit} from '@angular/core';
import {TrzDocumentComponent} from '@crm/app/trz-tools/ui/trz-document/trz-document.component';
import {WindowManagerService} from '@crm/app/window-manager/services/window-manager.service';
import {NbComponentSize} from '@nebular/theme/components/component-size';

@Component({
    selector: 'mcv-button-trz-document',
    templateUrl: './button-trz-document.component.html',
    styleUrls: ['./button-trz-document.component.scss']
})
export class ButtonTrzDocumentComponent implements OnInit {
    @Input() trz_documentId: number;
    @Input() onlyIcon = true;
    @Input() displayText = true;
    @Input() size: NbComponentSize = 'tiny';

    constructor(private windowService: WindowManagerService) {
    }

    ngOnInit(): void {
    }

    openBalance() {
        this.windowService.open(TrzDocumentComponent, {
            context: {documentId: this.trz_documentId},
            title: `Document #${this.trz_documentId}`
        });
    }
}
