import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {DataStore} from '@crm/app/store/services/store.service';
import {CseDocumentDataStore} from '@crm/app/store/store';
import {WindowManagerService} from '@crm/app/window-manager/services/window-manager.service';
import {CrmTableComponent} from '@crm/interfaces/CrmTableComponent';
import {CseDocument} from '@mcv/core';
import {CseDocumentService} from '@mcv/coreservices';
import {McvnotifierService} from '@mcv/ui';
import {NbDialogService} from '@nebular/theme';

@Component({
    selector: 'mcv-cse-document-list',
    templateUrl: './cse-document-list.component.html',
    styleUrls: ['./cse-document-list.component.scss']
})
export class CseDocumentListComponent extends CrmTableComponent<CseDocument> implements OnInit {
    @Input() cseId: number;
    @Input() owner_type: string;
    @Input() withTreezorComment: boolean;
    displayedColumns: string[] = [
        'id',
        'name',
        'filename',
        'date_update',
        'state'
    ];
    deleting = false;
    previewing = false;
    submitting = false;
    refusing = false;
    reviewing = false;
    reason: string;

    constructor(@Inject(CseDocumentDataStore) public store: DataStore<CseDocument>,
                private cseDocumentService: CseDocumentService,
                private fb: FormBuilder,
                private dialogService: NbDialogService,
                private windowManagerService: WindowManagerService,
                private notifierService: McvnotifierService) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (this.owner_type !== 'cse_employe') {
            this.displayedColumns.push('actions');
        }

        if (this.withTreezorComment) {
            this.displayedColumns.splice(2, 0, 'owner');
            this.displayedColumns.push('treezor_comment');
        }
    }

    async sendAllKycReview() {
        try {
            this.reviewing = true;
            await this.cseDocumentService
                .sendAllKycReview(this.cseId)
                .toPromise();
            this.notifierService.success('Documents envoyés pour validation chez Treezor !');
            this.searchFilter.updateValueAndValidity();
        } catch (e) {
            this.notifierService.error(e);

        } finally {
            this.reviewing = false;
        }
    }

    refreshData() {
        this.store.readDataList();
    }
}
