<form (ngSubmit)="saveBeneficiary()" [formGroup]="beneficiaryForm">
    <formly-form [fields]="beneficiaryFields" [form]="beneficiaryForm"
                 [model]="virementBeneficiary"
                 [options]="options"></formly-form>
    <br/>
    <div *ngIf="pendingValidation; else saveView">
        <div class="text-warning">Ce bénéficiaire est en attente de validation par Treezor</div>
    </div>
    <ng-template #saveView>
        <div class="p-2">
            <button [disabled]="saving || !beneficiaryForm.valid"
                    [nbSpinner]="saving"
                    fullWidth
                    nbButton
                    status="primary"
                    type="submit">
                {{ isNewBeneficiary ? 'Ajouter le bénéficiaire' : 'Mettre à jour le bénéficiaire' }}
            </button>
        </div>
    </ng-template>
</form>
<div *ngIf="showTransferView && virementBeneficiary !== null">
    <mcv-cse-employe-topup-payout-transfer [cseEmploye]="cseEmploye" [topup]="topup"
                                           [virementBeneficiary]="virementBeneficiary"></mcv-cse-employe-topup-payout-transfer>
</div>
<div *ngIf="canDoTransfer">
    <div class="p-2">
        <button (click)="toggleDisplayView()"
                fullWidth
                nbButton
                status="primary"
                type="submit">
            {{ showTransferView ? 'Fermer' : 'Faire un virement depuis ce compte' }}
        </button>
    </div>
</div>

