import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ContributionService} from '@mcv/coreservices';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'mcv-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

    searchFilter: FormGroup = this.fb.group({
        cse_employe_id: []
    });

    constructor(private repository: ContributionService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private fb: FormBuilder) {
    }

    ngOnInit(): void {
    }

    setDate(field: string, $event: Date) {
        this.searchFilter
            .get(field)
            .patchValue(`${$event.getFullYear()}/${$event.getMonth() + 1}/${$event.getDate()}`);
    }
}
