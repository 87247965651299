import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '@crm/app/auth/services/auth.service';
import { convertToFormData } from '@mcv/core';
import { CseDocumentService } from '@mcv/coreservices';
import { McvnotifierService } from '@mcv/ui';
import { NbMenuItem, NbMenuService } from '@nebular/theme';
import { FilePickerDirective, ReadFile } from 'ngx-file-helpers';
import { Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Component({
    selector: 'mcv-crm-kyc-document-upload',
    templateUrl: './kyc-document-upload.component.html',
    styleUrls: ['./kyc-document-upload.component.scss']
})
export class KycDocumentUploadComponent implements OnInit, OnDestroy {
    @ViewChild('filePicker', { static: true, read: FilePickerDirective }) filePicker: FilePickerDirective;
    @Input() docType: string;
    @Input() authorizedTypes: string[] = ['kyc_identity', 'kyc_address', 'kyb_statuts', 'kyb_pv'];
    @Input() ownerPublicId: number | string;
    @Input() cseId: number;
    @Input() ownerType: string;
    @Output() docSend: EventEmitter<boolean> = new EventEmitter<boolean>();
    docTypes: Record<string, string>;
    saving = false;
    loading = false;
    documentTypeId: any;
    documentForm: FormGroup;
    items: NbMenuItem[];
    tag = 'kyc-context-menu';
    subscription: Subscription = new Subscription();

    constructor(private cseDocumentService: CseDocumentService,
                private authService: AuthService,
                private nbMenuService: NbMenuService,
                private fb: FormBuilder,
                private notifierService: McvnotifierService) {
        this.loadItems();
        this.initContextMenu();
    }

    ngOnInit(): void {
        this.documentForm = this.fb.group({
            file: [],
            name: [null],
            owner_public_id: [null],
            owner_type: [null],
            cse_id: [null],
            trz_documentTypeId: [null]
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    async uploadDoc(event: ReadFile, documentTypeId: string): Promise<void> {
        if (!this.isSizeCorrect(event.underlyingFile)) {
            this.notifierService.error('La taille maximale autorisée du fichier est 4,5Mo');
            return;
        }
        try {
            this.saving = true;
            this.documentForm.patchValue({
                file: event.underlyingFile,
                name: this.docType,
                trz_documentTypeId: documentTypeId,
                owner_public_id: this.ownerPublicId,
                owner_type: this.ownerType,
                cse_id: this.cseId
            });
            const formData = convertToFormData(this.documentForm.value);
            await this.cseDocumentService
                .add(formData)
                .toPromise();
            this.notifierService.success('Document envoyé !');
            this.docSend.emit(true);
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.saving = false;
        }
    }

    loadItems() {
        this.loading = true;
        this.cseDocumentService
            .listTypes()
            .pipe(
                map(r => r.data),
                tap(_ => this.loading = false)
            )
            .toPromise()
            .then(r => {
                let types: any = {};
                if (this.authorizedTypes.length > 0) {
                    this.authorizedTypes.forEach(t => {
                        types[t] = r[t];
                    });
                } else {
                    types = r;
                }
                this.items = [];

                Object.keys(types)
                    .forEach(key => {
                        Object.keys(types[key])
                            .forEach(k => {
                                this.items.push({ title: types[key][k], data: { id: k, name: key } });
                            });
                    });
            })
            .catch(err => {
                this.notifierService.error(err);
                this.loading = false;
            });
    }

    private initContextMenu() {
        this.subscription
            .add(this.nbMenuService
                .onItemClick()
                .pipe(
                    filter(({ tag }) => tag === this.tag)
                )
                .subscribe(menu => {
                    this.documentTypeId = menu.item.data.id;
                    this.docType = menu.item.data.name;
                    this.filePicker.browse();
                })
            );
    }

    private isSizeCorrect(file: File): boolean {
        return file.size < 4.5 * 1000 * 1000;
    }
}
