import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'mcv-trz-wallet-avatar',
    templateUrl: './trz-wallet-avatar.component.html',
    styleUrls: ['./trz-wallet-avatar.component.scss']
})
export class TrzWalletAvatarComponent implements OnInit {
    @Input() walletId: number;
    @Input() bic: string;
    @Input() iban: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
