import {Component, Input, OnInit} from '@angular/core';
import {Balance, CseEmploye, CseEmployeTopup, VirementBeneficiary} from '@mcv/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BalancesService, VirementBeneficiaryService} from '@mcv/coreservices';
import {McvnotifierService} from '@mcv/ui';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-cse-employe-topup-payout-transfer',
    templateUrl: './cse-employe-topup-payout-transfer.component.html',
    styleUrls: ['./cse-employe-topup-payout-transfer.component.scss']
})
export class CseEmployeTopupPayoutTransferComponent implements OnInit {

    loading = true;
    currentBalance: number;
    maxAmount: number;
    balance: Balance;
    @Input() cseEmploye: CseEmploye;
    @Input() topup?: CseEmployeTopup;
    @Input() virementBeneficiary?: VirementBeneficiary;
    payoutForm: FormGroup = this.fb.group({
        label: [null, [Validators.required]],
        amount: [null, [Validators.required]],
        reason: [null, [Validators.required]],
        public_id: [null, [Validators.required]]
    });

    refundFields: FormlyFieldConfig[] = [
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'Veuillez saisir le label, montant et la raison du virement'},
            fieldGroup: [
                {
                    key: 'label',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Label',
                        placeholder: '',
                        required: true
                    }
                },
                {
                    key: 'amount',
                    className: 'col-6',
                    type: 'currency',
                    templateOptions: {
                        label: 'Montant',
                        placeholder: '',
                        required: true,
                        type: 'number'
                    }
                },
                {
                    key: 'reason',
                    className: 'col-6',
                    type: 'select',
                    templateOptions: {
                        label: 'Raison',
                        placeholder: '',
                        required: true,
                        options: [
                            {label: 'Autre', value: 'other'},
                            {label: 'refund_topup', value: 'refund_topup'},
                            {label: 'refund_payement', value: 'refund_payement'}
                        ]
                    }
                }
            ]
        }];


    constructor(private modalService: BsModalService,
                private fb: FormBuilder,
                private virementBeneficiaryService: VirementBeneficiaryService,
                private notifierService: McvnotifierService,
                private balancesService: BalancesService) {
    }

    ngOnInit(): void {
        this.loadData();
        this.payoutForm.patchValue({
            cse_employe_public_id: this.cseEmploye.public_id,
            public_id: this.virementBeneficiary.public_id
        });
    }

    async loadData(): Promise<void> {
        try {
            this.loading = true;
            this.balance = await this.balancesService
                .view(this.cseEmploye.trz_walletId)
                .pipe(map(r => r.data))
                .toPromise();
            this.currentBalance = Number(this.balance.currentBalance);
            this.virementBeneficiary.is_active = this.virementBeneficiary.state === 'activated';
            this.virementBeneficiary.amount = this.topup?.amount ?? 1;
            if (this.topup?.source === 'topup') {
                this.virementBeneficiary.reason = 'refund_topup';
                this.maxAmount = Math.min(this.currentBalance, this.virementBeneficiary.amount);
            } else if (this.topup?.source === 'contribution') {
                this.virementBeneficiary.reason = 'refund_payement';
                this.maxAmount = Math.min(this.currentBalance, this.virementBeneficiary.amount);
            } else {
                this.virementBeneficiary.reason = 'other';
                this.maxAmount = this.currentBalance;
            }
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.loading = false;
        }
    }


    async doRefund(): Promise<void> {
        try {
            this.loading = true;
            this.payoutForm.patchValue({
                cse_employe_public_id: this.cseEmploye.public_id,
                public_id: this.virementBeneficiary.public_id
            });
            await this.virementBeneficiaryService.doVirement(this.payoutForm.value)
                .toPromise();
            this.notifierService.success('La demande de virement à bien été effectué');
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.loading = false;
        }
    }
}
