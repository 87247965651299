import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'mcv-admin-cse-employe-remove-return',
    templateUrl: './admin-cse-employe-remove-return.component.html',
    styleUrls: ['./admin-cse-employe-remove-return.component.scss']
})
export class AdminCseEmployeRemoveReturnComponent {
    @Input() result: any;
}
