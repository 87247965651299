import {HttpParams} from '@angular/common/http';
import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Cse} from '@mcv/core';
import {CseService} from '@mcv/coreservices';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-cse-select',
    templateUrl: './cse-select.component.html',
    styleUrls: ['./cse-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CseSelectComponent),
            multi: true
        }
    ]
})
export class CseSelectComponent implements OnInit, ControlValueAccessor {
    @Input() value: number; // cse_id
    disabled: boolean;
    options: Cse[];
    filteredOptions$: Observable<Cse[]>;
    cse: Cse;
    cse_id;

    constructor(private cseService: CseService) {
    }

    onChange: any = (val: number) => {
    };

    onTouch: any = (val: number) => {
    };

    async ngOnInit(): Promise<void> {
        this.options = await this.cseService
            .getList(1, 9999, new HttpParams().set('sort', 'legalName'))
            .pipe(
                map(r => r.data)
            )
            .toPromise();
        this.filteredOptions$ = of(this.options);
        if (this.cse_id) {
            this.selectCse(this.cse_id);
            this.cse_id = null;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: string | number): void {
        if (!this.options) {
            this.cse_id = obj;
            return;
        }
        if (obj) {
            this.selectCse(obj);
        }
    }

    onSelectionChange($event: string | Cse) {
        if (typeof $event === 'object' && !!$event) {
            this.cse = $event;
            this.onChange($event?.id);
        }
        this.filteredOptions$ = of(this.options);
    }

    onModelChange($event: string | Cse) {
        if (typeof $event === 'string' && $event.trim().length > 0) {
            this.filteredOptions$ = of(this.filter($event));
            return;
        }
        if (typeof $event === 'string' && $event === '') {
            this.onChange(null);
        }
    }

    private selectCse(cse_id: string | number) {
        this.cse = this.options.find(c => c.id === +cse_id);
    }

    private filter(filterValue: string): Cse[] {
        return this.options.filter(optionValue => optionValue.legalName
            .toLowerCase()
            .includes(filterValue));
    }
}
