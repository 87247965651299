import {Pipe, PipeTransform} from '@angular/core';
import {CseUser} from '@mcv/core';

@Pipe({
    name: 'cseUserTreezorComment'
})
export class CseUserTreezorCommentPipe implements PipeTransform {

    transform(value: CseUser, ...args: unknown[]): unknown {
        let output = '';
        if (value.kyc_state === 'refused') {
            output += '<ul>';
            output += '<li>stat: ' + value.kyc_state + '</li>';
            output += '<li>message: ' + value.kyc_message + '</li>';
            output += '</ul>';
        }
        return output;
    }

}
