<input (ngModelChange)="onModelChange($event)"
       [nbAutocomplete]="auto"
       [ngModel]="cse?.legalName"
       fullWidth
       nbInput
       placeholder="Choisir un CSE"
       type="search"/>

<nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
    <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option">
        #{{option.id}} {{ option.legalName }}
    </nb-option>
</nb-autocomplete>
